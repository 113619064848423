<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <h1>Osiguranja</h1>
            </b-col>
            <b-col cols="6" class="print-show">
                <mara-table :item="selectedClient"></mara-table>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/insurances/create">
                    <b-btn variant="primary">Novo osiguranje</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="insurances">
            <b-col>
                <osta-table position
                            :items="insurances"
                            :fields="insurancesFields"
                            @rowClicked="goToInsurance">
                </osta-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'insurances',
        data() {
            return {
                insurances: null,
                insurancesFields: [
                    {
                        key: 'date_from',
                        label: 'Datum od',
                        sortable: true
                    },
                    {
                        key: 'date_to',
                        label: 'Datum do',
                        sortable: true
                    },
                    {
                        key: 'base',
                        label: 'Osnovica'
                    },
                    {
                        key: 'pay_date',
                        label: 'Datum uplate',
                        sortable: true
                    },
                    {
                        key: 'total',
                        label: 'Ukupni doprinosi'
                    }
                ]
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            goToInsurance(id) {
                this.$router.push('/insurances/' + id);
            },
            getInsurances() {
                axiOsta.post(api.clients + 'insurances', {
                    client_id: this.selectedClient.id
                }).then(response => {
                    if (response.status === 200) {
                        this.insurances = response.data;
                    }
                })
            }
        },
        created() {
            if (this.selectedClient != null) {
                this.getInsurances();
            }
        },
        watch: {
            selectedClient: function () {
                this.getInsurances();
            }
        }
    }

</script>
